import { PLATFORM_ENVIRONMENTS } from "./constants";

const PRODUCTION_ENVIRONMENT_KEY = "prod";

export function getPlatformEnvironments() {
  return PLATFORM_ENVIRONMENTS[process.env.VUE_APP_ENVIRONMENT];
}

export function getDefaultEnvironment() {
  return process.env.VUE_APP_ENVIRONMENT === PRODUCTION_ENVIRONMENT_KEY
    ? PRODUCTION_ENVIRONMENT_KEY
    : null;
}
