import axios from "axios";
import router from "@/router";

function handleError(error) {
  if (error.response.status == 401) {
    window.$cookies.remove("loggedInUser");
    router.push("/login");
  }
}

function getAxiosParameters() {
  return {
    withCredentials: true
  };
}

export async function isUserLoggedIn() {
  return axios
    .get(constructUrl("/user/auth/check"), getAxiosParameters())
    .then(function (response) {
      return response && response.data;
    });
}

export async function fetchUserInfo() {
  return axios
    .get(constructUrl("/user/auth/info"), getAxiosParameters())
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function fetchImportSheets(env) {
  return axios
    .get(constructUrl(`/imports/list/${env}`), getAxiosParameters())
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function fetchSheetDataById(sheetId, tab, range) {
  return axios
    .get(
      constructUrl(`/google/import/data/${sheetId}`),
      Object.assign(getAxiosParameters(), {
        params: {
          tab,
          range
        }
      })
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function fetchImportDataByRegionId(regionId) {
  return axios
    .get(constructUrl(`/imports/${regionId}`), getAxiosParameters())
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

function constructUrl(resourceUrl) {
  const BASE_URL = process.env.VUE_APP_API_HOST;
  return `${BASE_URL}${resourceUrl}`;
}

export async function fetchMetadataExistsStatusFromPlatForm(
  platformEnv,
  regionId,
  metadataKey,
  data
) {
  return axios
    .post(
      constructUrl(
        `/imports/${regionId}/getMetadataExistStatus/${platformEnv}/${metadataKey}`
      ),
      { data },
      getAxiosParameters()
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function validateLocations(platFormEnv, sheetRegion, data){
  return axios
    .post(
      constructUrl(
        `/imports/validateLocations/${platFormEnv}/${sheetRegion}`
      ),
      { data },
      getAxiosParameters()
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function validateLocationsBySerialNumber(platFormEnv, sheetRegion, data){
  return axios
  .post(
    constructUrl(
      `/imports/validateLocationsBySerialNumber/${platFormEnv}/${sheetRegion}`
    ),
    { data },
    getAxiosParameters()
  )
  .then(function (response) {
    return response && response.data;
  })
  .catch(handleError);
}

export async function fetchDependentMandatoryFromPlatForm(
  platformEnv,
  regionId,
  metadataKey,
  data
) {
  return axios
    .post(
      constructUrl(
        `/imports/${regionId}/getDependentMandatoryStatus/${platformEnv}/${metadataKey}`
      ),
      { data },
      getAxiosParameters()
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function fetchAssetdataBySerialNumberFromPlatForm(
  regionId,
  platformEnv,
  serialNumbers
) {
  return axios
    .post(
      constructUrl(`/imports/${regionId}/getAssetData/${platformEnv}`),
      { data: serialNumbers },
      getAxiosParameters()
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function fetchAssetsTypeFromPlatform (platformEnv, data){
  return axios
  .post(
    constructUrl(
      `/imports/getAssetsType/${platformEnv}`
    ),
    { data },
    getAxiosParameters()
  )
  .then(function (response) {
    return response && response.data;
  })
  .catch(handleError);
}

export async function postAssetDataToImport(platFormEnv, assetData, type, region, regionCode, importId) {
  return axios
    .post(
      constructUrl(`/imports/import/${platFormEnv}`),
      {
        data: {
          "assetData": assetData,
          "type": type,
          "region": region,
          "regionCode": regionCode,
          "importId": importId
        }
      },
      getAxiosParameters()
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function getImportSummary(platFormEnv,importId){
  return axios
    .get(
      constructUrl(`/imports/summary/${platFormEnv}/${importId}`),
      getAxiosParameters()
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}

export async function clearSheet(regionId,rowCount){
  return axios
    .get(
      constructUrl(`/imports/clearSheet/${regionId}/${rowCount}`),
      getAxiosParameters()
    )
    .then(function (response) {
      return response && response.data;
    })
    .catch(handleError);
}
