<template>
  <b-row class="text-left header-bar">
    <b-col cols="2">
      <b>Import Type</b>
      <p class="header-item-value">
        {{ importLabel }}
        <span v-if="importDescription">
          -
          <b-icon icon="info-circle" v-b-tooltip.hover :title="importDescription"></b-icon>
        </span>
      </p>
    </b-col>
    <b-col cols="1" class="center-align">
      <b>Region</b>
      <p class="header-item-value">{{ country }}</p>
    </b-col>
    <b-col cols="2">
      <b>ServiceNow Environment</b>
      <b-form-select
        class="filter-select"
        :value="getSelectedPlatform()"
        :options="environments"
        @change="onEnvChange"
      />
    </b-col>
    <b-col cols="1" class="center-align">
      <b>Total Assets</b>
      <p class="header-item-value">{{ assetCount }}</p>
    </b-col>
    <b-col cols="1" class="center-align">
      <b>Total Errors</b>
      <p class="header-item-value">{{ errorCount }}</p>
    </b-col>
    <b-col cols="3" >
      <div class="text-right">
        <div v-if="!isAllPlatformAssetDataLoaded" class="header-notification-msg">
          <b-spinner type="grow" />
          <span>fetching and checking asset data from servicenow...</span>
        </div>
        <div>
          <b-form inline>
          <b-icon v-if="isAllPlatformAssetDataLoaded" icon="funnel" font-scale="1.75" class = "funnel-icon" />
          <b-form-select  
            v-if="isAllPlatformAssetDataLoaded"
            class="filter-select"
            :value="imports.filter.selectedFilter"
            :options="options"
            @change="onFilterChange"
          />
          </b-form>
        </div>
      </div>
    </b-col>
    <b-col cols="2">
      <a
        :href="`https://docs.google.com/spreadsheets/d/${sheetId}/edit`"
        target="_blank"
        class="open_sheet_link"
      >
        <b-button variant="primary">Open Sheet</b-button>
      </a>
      <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
        <b-button
          id="import-btn"
          :disabled="!isImportEnabled.status || assetCount == 0 || imports.showStatus"
          class="import-btn"
          variant="success"
          @click="importAsset"
        >Import</b-button>
      </span>
      <b-tooltip
        v-if="isImportEnabled.errorMessages.length !=0"
        target="disabled-wrapper"
        triggers="hover"
      >{{ isImportEnabled.errorMessages.join(",") }}</b-tooltip>
      <b-modal
        :visible="showAssetImportProcess"
        title="Importing asset data"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        centered
      >
        <template v-slot:default="{  }">
          <div
            v-if="
              imports.importStatus.totalAssetImported !==
              imports.importStatus.totalAssetToBeImported
            "
            class="asset-importing-status"
          >
            <b-spinner style="width: 3rem; height: 3rem;" type="grow" />
            <p>{{ imports.importStatus.state }}</p>
            <p>
              Adding assets to import {{ imports.importStatus.totalAssetImported }}/{{
              imports.importStatus.totalAssetToBeImported
              }}
            </p>
            <p>Please do not refresh or close the page during the import process</p>
          </div>
          <div v-if="imports.showStatus" class="asset-importing-status">
            <b-spinner
              v-if="!imports.summaryUpdated"
              style="width: 3rem; height: 3rem;"
              label="Large Spinner"
              type="grow"
            />
            <p v-if="!imports.summaryUpdated">Updating Import Status</p>
            <h5 v-else>Importing process done</h5>
            <div class="import-summary">
              <div
                :class="[imports.importSummary.failure==0?'all-success':'']"
                v-if="imports.importSummary.success>0"
              >{{imports.importSummary.success}} asset{{imports.importSummary.success==1?' is':'s are'}} successfully imported</div>
              <div v-if="imports.summaryUpdated && imports.importSummary.failure==0" class="all-success-msg" >Kindly note the data in the Google sheets are automatically deleted as the assets are successfully imported.</div>
              <div
                v-if="imports.importSummary.failure>0"
              >{{imports.importSummary.failure}} asset{{imports.importSummary.failure==1?'':'s'}} could not be imported due to some errors</div>
              <div v-if="imports.importSummary.failedAssets.length > 0" class="failed_assets">
                <h6>
                  Failed Assets
                  <b-link variant="primary" @click="copyFailedAssets()">
                    <b-icon icon="files" />Copy
                  </b-link>
                </h6>
                <ul>
                  <li v-for="item in imports.importSummary.failedAssets" :key="item">{{ item }}</li>
                </ul>
              </div>
            </div>

            <div
              class="error_information"
              v-if="imports.importSummary.failure>0"
            >Please contact CATS team for further details</div>
            <b-button
              v-if="imports.summaryUpdated"
              variant="primary"
              @click="closeImportStatusModel()"
            >OK</b-button>
          </div>
          <div
            class="import-status-msg"
            v-if="imports.summaryUpdated"
          >* Import Status field will not be available after page reload</div>
        </template>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { getPlatformEnvironments } from "@/helpers/utils";

export default {
  name: "ImportHeader",
  props: {
    importLabel: { type: String, default: "" },
    importDescription: { type: String, default: "" },
    country: { type: String, default: "" },
    assetCount: { type: Number, default: 0 },
    errorCount: { type: Number, default: 0 },
    sheetId: { type: String, default: "" },
    isImportEnabled: { type: Object }
  },
  data() {
    return {
      options: [
        { value: null, text: "Show all assets" },
        { value: "errors", text: "Assets with validation errors"},
        { value: "exists", text: "Assets existing in servicenow" },
        { value: "new", text: "Assets new to servicenow" }
      ],
      environments: getPlatformEnvironments()
    };
  },
  computed: {
    ...mapState({
      imports: state => state.imports,
      platformEnv: state => state.platformEnv
    }),
    isAllPlatformAssetDataLoaded: function () {
      return (
        this.imports.importData.data &&
        this.imports.importData.data.length ==
          Object.keys(this.imports.platformAssetData).length
      );
    },
    showAssetImportProcess: function () {
      return this.imports.importStatus.state != "";
    }
  },
  methods: {
    getSelectedPlatform() {
      return this.$route.query.selectedPlatform;
    },
    onFilterChange(value) {
      this.$store.dispatch("setFilter", {
        filterKey: "selectedFilter",
        filterValue: value
      });
    },
    importAsset() {
      this.$store.dispatch("importAssetData", {
        platformEnv: this.$route.query.selectedPlatform
      });
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1", "#btnShow"); // eslint-disable-line
    },
    onEnvChange(value) {
      window.location.href = `/import/${this.$route.params.regionId}?selectedPlatform=${value}`;
    },
    pageReload() {
      location.reload();
    },
    closeImportStatusModel() {
      this.imports.importStatus.state = "";
    },
    copyFailedAssets(){
      const clipboardy = require('clipboardy');
      clipboardy.write(this.imports.importSummary.failedAssets)
    }
  }
};
</script>

<style>
.header-bar {
  padding: 1%;
  background-color: #f5f7fa;
}
.header-item-value {
  margin-bottom: 0;
}
.header-notification-msg span {
  font-size: 12px;
  vertical-align: middle;
}
.filter-select {
  float: right;
}

.asset-importing-status {
  text-align: center;
}

.asset-importing-status p {
  font-style: italic;
  font-size: 12px;
}
.open_sheet_link {
  margin-right: 10px;
}

.error_information {
  padding-bottom: 15px;
  font-style: italic;
  font-size: 14px;
}

.failed_assets ul {
  padding: 10px;
  background: #cccccc;
  width: 100%;
  max-height: 180px;
  text-align: left;
  margin: auto;
  color: red;
  overflow-y: scroll;
}

.failed_assets {
  margin: 15px 0px;
}

.import-summary {
  text-align: left;
  padding: 10px;
}
.import-status-msg {
  padding-top: 25px;
  font-style: italic;
  font-size: 11px;
  text-align: right;
}

.all-success {
  text-align: center;
}

.all-success-msg{
    font-style: italic;
    font-size: 14px;
    margin-top: 10px;
    background-color: yellow;
    border: 1px solid #222;
    padding: 5px;
}
.center-align{
  text-align: center;
}

.funnel-icon {
margin: 0px 8px;
}
</style>
