import Vue from "vue";
import Router from "vue-router";

import ImportListPage from "@/pages/ImportList";
import ImportPage from "@/pages/Import";
import LogoutPage from "@/pages/Logout";
import { isUserLoggedIn } from "./api";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "imports",
      component: ImportListPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/import/:regionId",
      name: "importData",
      component: ImportPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/logout",
      name: "logout",
      component: LogoutPage,
      meta: {
        requiresAuth: false
      }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      if (await isUserLoggedIn()) {
        next();
      }
    } catch (err) {
      window.location = `${process.env.VUE_APP_API_HOST}/user/auth/login`;
    }
  } else {
    next();
  }
});

export default router;
