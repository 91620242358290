import Vue from "vue";
import { BootstrapVue, OverlayPlugin, IconsPlugin } from 'bootstrap-vue'
import App from "./App.vue";
import { createStore } from "./store";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(OverlayPlugin);
Vue.use(IconsPlugin)

Vue.config.productionTip = false;

const store = createStore();

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});
