import {
  fetchImportDataByRegionId,
  fetchMetadataExistsStatusFromPlatForm,
  fetchDependentMandatoryFromPlatForm,
  fetchAssetsTypeFromPlatform,
  fetchAssetdataBySerialNumberFromPlatForm,
  postAssetDataToImport,
  getImportSummary,
  clearSheet,
  validateLocations,
  validateLocationsBySerialNumber
} from "@/api/index";
import { Promise } from "core-js";
import { v4 as uuidv4 } from "uuid";

const state = {
  importData: {},
  selectedAssetIndices: [],
  metadataExistStatus: {},
  dependentMandatoryStatus: {},
  platformAssetData: {},
  locationValidityStatus: {},
  locationValidityBySerialNumber: {},
  assetTypeMap: {},
  filter: {
    selectedFilter: null,
  },
  importStatus: {
    state: "",
    totalAssetToBeImported: 0,
    totalAssetImported: 0,
    importId: "",
  },
  importSummary: {
    success: 0,
    failure: 0,
    pending: 0,
    details: {},
    failedAssets: [],
  },
  showStatus: false,
  summaryUpdated: false,
  fixedAssetPolicyDate: '2021-01-01'
};

const getters = {};

const actions = {
  async getMetadataExistStatusFromPlatform(
    { commit },
    { importData, platformEnv }
  ) {
    commit("SET_APP_BUSY_STATE", {
      isAppBusy: true,
      message: "fetching meta exist statuses from platform",
    });
    return Promise.all(
      importData.checkMetadataItems.map((item) => {
        const data = Array.from(
          new Set(
            importData.data
              .map((asset) => {
                const values = item.keys.map((key) => asset[key]);
                if (!values.includes("")) {
                  return values.join(" ");
                }
              })
              .filter((value) => value)
          )
        );

        if (data.length) {
          return fetchMetadataExistsStatusFromPlatForm(
            platformEnv,
            importData.regionId,
            item.metadataKey,
            data
          ).then((metadataExistStatus) => {
            commit("SET_METADATA_EXISTS_STATUS", {
              metadataKey: item.metadataKey,
              data: metadataExistStatus,
            });
          });
        }
      })
    ).then(() => {
      commit("SET_APP_BUSY_STATE", {
        isAppBusy: false,
        message: "",
      });
    });
  },
  async getLocationValidityStatusFromPlatform(
    { commit },
    { importData, platformEnv }
  ) {
    commit("SET_APP_BUSY_STATE", {
      isAppBusy: true,
      message: "fetching location validity statuses from platform",
    });
    return Promise.all(

      importData.checkLocationFieldForSelectedRegion.map((field) => {
        const data = Array.from(
          new Set(
            importData.data
              .map((asset) => { return asset[field]; })
              .filter((value) => value)
          )
        );

        if (data.length) {
          return validateLocations(
            platformEnv,
            importData.country,
            data
          ).then((locationValidityStatus) => {
            commit("SET_LOCATION_VALIDITY_STATUS", {
              fieldName: field,
              data: locationValidityStatus,
            });
          });
        }
      })
    ).then(() => {
      commit("SET_APP_BUSY_STATE", {
        isAppBusy: false,
        message: "",
      });
    });
  },
  async getLocationValidityBySerialNumberFromPlatform(
    { commit },
    { importData, platformEnv }
  ) {
    if (importData.checkPurchaseLocationFromPlatform) {
      commit("SET_APP_BUSY_STATE", {
        isAppBusy: true,
        message: "fetching location validity statuses from platform",
      });
      const data = Array.from(
        new Set(
          importData.data
            .map((asset) => { return asset["serial_number"]; })
            .filter((value) => value)
        )
      );
      if (data.length) {
        new Promise( () =>  
          validateLocationsBySerialNumber(
            platformEnv,
            importData.country,
            data
          ).then((locationValidityStatus) => {
            commit("SET_LOCATION_VALIDITY_STATUS_BY_SERIAL_NUMBER", {
              locationValidityBySerialNumber: locationValidityStatus,
            });
          }).then( () => 
            commit("SET_APP_BUSY_STATE", {
              isAppBusy: false,
              message: "",
            })
          )
        )
         
      }
    }
  },
  async getDependentMandatoryStatusFromPlatform(
    { commit },
    { importData, platformEnv }
  ) {
    commit("SET_APP_BUSY_STATE", {
      isAppBusy: true,
      message: "fetching meta exist statuses from platform",
    });
    return Promise.all(
      importData.dependentMandatoryFields.map((item) => {
        const data = Array.from(
          new Set(
            importData.data
              .map((asset) => {
                const values = [asset[item["dependentKey"]]];
                if (!values.includes("")) {
                  return values.join(" ");
                }
              })
              .filter((value) => value)
          )
        );

        if (data.length) {
          return fetchDependentMandatoryFromPlatForm(
            platformEnv,
            importData.regionId,
            item.metadataKey,
            data
          ).then((dependentMandatoryStatus) => {
            commit("SET_DEPENDANT_MANDATORY_STATUS", {
              metadataKey: item.metadataKey,
              data: dependentMandatoryStatus,
            });
          });
        }
      })
    ).then(() => {
      commit("SET_APP_BUSY_STATE", {
        isAppBusy: false,
        message: "",
      });
    });
  },
  async getAssetDataBySerialNumberFromPlatform(
    { commit },
    { regionId, platformEnv, importData }
  ) {
    let serialNumbers = importData.data.map(
      ({ serial_number }) => serial_number
    );
    let assetIndex = 0;
    while (serialNumbers.length) {
      const serialNumberBatch = serialNumbers.splice(0, 500);
      const assetData = await fetchAssetdataBySerialNumberFromPlatForm(
        regionId,
        platformEnv,
        serialNumberBatch
      );

      for (const serialNumber of serialNumberBatch) {
        commit("SET_PLATFORM_ASSET_DATA", {
          assetIndex,
          assetData: assetData[serialNumber],
        });
        assetIndex++;
      }
    }
  },
  async getAssetsTypeFromPlatform({commit}, {platformEnv, importData}){
    commit("SET_APP_BUSY_STATE", {
      isAppBusy: true,
      message: "fetching asset type from platform",
    });
      if (importData.assetTypeBasedValidation.length){
        const data = importData.data.map((asset) => {
          return asset["serial_number"]
        })
        
      if (data.length) {
        new Promise( () =>
         fetchAssetsTypeFromPlatform(
          platformEnv,
          data
        ).then((assetTypeMap) => {
          commit("SET_ASSET_TYPE", {
             assetTypeMap,
          });
        })
        ).then( () => 
          commit("SET_APP_BUSY_STATE", {
            isAppBusy: false,
            message: "",
          })
        )
      }
    }
},
  getImportDataByRegionId({ commit, dispatch }, { regionId, platformEnv }) {
    commit("SET_APP_BUSY_STATE", {
      isAppBusy: true,
      message: "fetching import data from google sheets",
    });

    commit("RESET_STORE_STATE");

    return fetchImportDataByRegionId(regionId).then((data) => {
      dispatch("getMetadataExistStatusFromPlatform", {
        importData: data,
        platformEnv,
      });
      dispatch("getLocationValidityStatusFromPlatform", {
        importData: data,
        platformEnv,
      });
      dispatch("getLocationValidityBySerialNumberFromPlatform", {
        importData: data,
        platformEnv,
      });
      dispatch("getDependentMandatoryStatusFromPlatform", {
        importData: data,
        platformEnv,
      });
      dispatch("getAssetsTypeFromPlatform", {
        importData: data,
        platformEnv,
      });
      dispatch("getAssetDataBySerialNumberFromPlatform", {
        importData: data,
        regionId,
        platformEnv,
      });

      return commit("SET_IMPORT_DATA", { data });
    });
  },
  toggleAssetSelection({ commit, state }, { assetIndex }) {
    if (!state.selectedAssetIndices.includes(assetIndex)) {
      commit("ADD_ASSET_INDEX_IN_SELECTION", { assetIndex });
    } else {
      commit("REMOVE_ASSET_INDEX_IN_SELECTION", { assetIndex });
    }
  },
  toggleAllAssetSelection({ commit, state }) {
    if (state.selectedAssetIndices.length) {
      commit("REMOVE_ALL_ASSET_INDEX_IN_SELECTION");
    } else {
      if (state.importData.data) {
        state.importData.data.forEach((asset, assetIndex) => {
          if (
            Object.keys(asset.errors).length === 0 &&
            !state.selectedAssetIndices.includes(assetIndex)
          ) {
            commit("ADD_ASSET_INDEX_IN_SELECTION", { assetIndex });
          }
        });
      }
    }
  },
  setFilter({ commit }, { filterKey, filterValue }) {
    commit("SET_FILTER", {
      filterKey,
      filterValue,
    });
  },

  async importAssetData({ commit, state }, { platformEnv }) {

    commit("SET_SHOW_STATUS", false)

    var import_id = uuidv4();
    commit("SET_IMPORT_STATUS", {
      state: "INITIATED",
      totalAssetToBeImported: state.importData.data.length,
      totalAssetImported: 0,
      importId: import_id,
    });
    let assetData = [
      ...state.importData.data.map((item) => {
        const { errors, ...dataWithoutError } = item; // eslint-disable-line no-unused-vars
        return dataWithoutError;
      }),
    ];
    let assetImportpromises = [];
    while (assetData.length) {
      const assetDataBatch = assetData.splice(0, 100);
      await postAssetDataToImport(
        platformEnv,
        assetDataBatch,
        state.importData.label,
        state.importData.country,
        state.importData.countryCode,
        import_id
      ).then(() => {
        commit("SET_IMPORT_STATUS", {
          state: "IMPORTING",
          totalAssetToBeImported: state.importData.data.length,
          totalAssetImported:
            assetDataBatch.length + state.importStatus.totalAssetImported,
          importId: import_id,
        });
      });
    }
    return Promise.all(assetImportpromises).then(() => {

      commit("SET_SHOW_STATUS", true)
      setTimeout(this.dispatch, 5000, "loadSummary", {
        platformEnv,
      });
    });
  },
  loadSummary({ commit, state }, { platformEnv }) {
    getImportSummary(platformEnv, state.importStatus.importId).then(response => {
      let failedAssets = Object.keys(response.details).filter(key => response.details[key] == 'failure')

      commit("SET_IMPORT_SUMMARY", { ...response, failedAssets })

      if ((response.success + response.failure) < state.importStatus.totalAssetToBeImported) {
        setTimeout(this.dispatch, 5000, "loadSummary", {
          platformEnv,
        });
      }
      else {
        if (response.success == state.importStatus.totalAssetToBeImported) {
          clearSheet(state.importData.regionId, state.importStatus.totalAssetToBeImported).then(() => console.log('Done'));
        }
        commit("SET_SUMMARY_UPDATED", true)
      }
    })
  }
};

const mutations = {
  SET_IMPORT_SUMMARY: (state, { success, failure, pending, details, failedAssets }) => {
    state.importSummary.success = success
    state.importSummary.failure = failure
    state.importSummary.pending = pending
    state.importSummary.details = details
    state.importSummary.failedAssets = failedAssets
  },
  SET_SHOW_STATUS: (state, showStatus) => {
    state.showStatus = showStatus;
  },
  SET_SUMMARY_UPDATED: (state, summaryUpdated) => {
    state.summaryUpdated = summaryUpdated;
  },
  SET_IMPORT_DATA: (state, { data }) => {
    state.importData = data;
  },
  ADD_ASSET_INDEX_IN_SELECTION: (state, { assetIndex }) => {
    state.selectedAssetIndices.push(assetIndex);
    state.selectedAssetIndices = [...state.selectedAssetIndices];
  },
  REMOVE_ASSET_INDEX_IN_SELECTION: (state, { assetIndex }) => {
    state.selectedAssetIndices = state.selectedAssetIndices.filter(
      (index) => index != assetIndex
    );
  },
  REMOVE_ALL_ASSET_INDEX_IN_SELECTION: (state) => {
    state.selectedAssetIndices = [];
  },
  SET_ASSET_IMPORT_STATUS: (state, { serialNumber, status }) => {
    const assetIndex = state.data.findIndex(
      (asset) => asset["Serial number"] === serialNumber
    );
    state.data[assetIndex].import_status = status;
    state.data = [...state.data];
  },
  SET_METADATA_EXISTS_STATUS: (state, { metadataKey, data }) => {
    state.metadataExistStatus[metadataKey] = data;
    state.metadataExistStatus = { ...state.metadataExistStatus };
  },
  SET_LOCATION_VALIDITY_STATUS: (state, { fieldName, data }) => {
    state.locationValidityStatus[fieldName] = data;
    state.locationValidityStatus = { ...state.locationValidityStatus };
  },
  SET_LOCATION_VALIDITY_STATUS_BY_SERIAL_NUMBER: (state, {locationValidityBySerialNumber}) => {
    state.locationValidityBySerialNumber = locationValidityBySerialNumber;
  },
  SET_DEPENDANT_MANDATORY_STATUS: (state, { metadataKey, data }) => {
    state.dependentMandatoryStatus[metadataKey] = data;
    state.dependentMandatoryStatus = { ...state.dependentMandatoryStatus };
  },
  SET_PLATFORM_ASSET_DATA: (state, { assetIndex, assetData }) => {
    state.platformAssetData[assetIndex] = assetData;
    state.platformAssetData = { ...state.platformAssetData };
  },
  SET_FILTER: (state, { filterKey, filterValue }) => {
    state.filter[filterKey] = filterValue;
    state.filter = { ...state.filter };
  },
  SET_IMPORT_STATUS: (
    state,
    { state: importState, totalAssetToBeImported, totalAssetImported, importId }
  ) => {
    state.importStatus = {
      state: importState,
      totalAssetToBeImported,
      totalAssetImported,
      importId,
    };
  },

  RESET_STORE_STATE: (currentState) => {
    currentState = { ...state };
    return currentState;
  },
  SET_ASSET_TYPE: (state, { assetTypeMap }) => {
    state.assetTypeMap = assetTypeMap;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
