export default {
  SET_PLATFORM_ASSETS: (state, { assets }) => {
    state.platformAssets = assets;
  },

  SET_USER_INFO: (state, { userInfo }) => {
    state.userInfo = userInfo;
  },

  SET_IMPORT_SHEETS: (state, { importSheets }) => {
    state.importSheets = importSheets;
  },

  SET_PLATFORM_ENV: (state, { platformEnv }) => {
    state.platformAssets = [];
    state.platformEnv = platformEnv;
  },

  SET_IMPORT_STATUS: (state, { importStatus }) => {
    state.importStatus = importStatus;
  },

  SET_APP_BUSY_STATE: (state, { isAppBusy, message }) => {
    state.isAppBusy = isAppBusy;
    state.loadingMessage = message;
  }
};
