import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import imports from "./modules/imports";

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    modules: {
      imports
    },
    state: {
      assets: [],
      platformAssets: [],
      platformEnv: null,
      entries: [],
      userInfo: null,
      importSheets: [],
      importStatus: null,
      isAppBusy: true,
      loadingMessage: ""
    },
    actions,
    mutations
  });
}
