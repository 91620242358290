<template>
  <div v-if="importSheets" class="imports-sheets">
    <b-row>
      <b-col cols="9">
       
        <b-tabs pills card vertical>
            <template v-slot:tabs-start>
              <p>Select type of import to ServiceNow</p>
            </template>
          <b-tab
            v-for="importSheet in importSheets"
            :key="importSheet.type"
            class="import-list-tab"
          >
           <template v-slot:title>
            <div>
              {{importSheet.label}} <span v-if="importSheet.description">- <b-icon icon="info-circle" 
                  v-b-tooltip.hover :title="importSheet.description">
                </b-icon> </span>
                </div>
           </template>
            <b-row class="region-list">
              <b-table
                hover
                striped
                :fields="fields"
                :items="importSheet.regions"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(link_to_sheet)="data">
                  <a
                    :href="`https://docs.google.com/spreadsheets/d/${data.item.sheetId}/edit`"
                    target="_blank"
                  >
                    Open
                  </a>
                </template>
                <template v-slot:cell(flagImage)="data">
                  <div class="import-region" @click="selectRegion(data.item)">
                    <b-form-radio name="country" class="country-radio-button">
                      <p class="country-name">
                        {{ data.item.country }}
                      </p>
                    </b-form-radio>
                  </div>
                </template>
              </b-table>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col cols="3">
        <b-card class="import-option-card">
          <p>Selected Region</p>
          <h3>{{ (selectedRegion && selectedRegion.country) || "None" }}</h3>
          <b-form-select
            v-model="selectedPlatform"
            :disabled="!selectedRegion"
            :options="platformEnvs"
          />
          <b-button
            class="next-btn"
            :disabled="!selectedRegion || !selectedPlatform"
            variant="primary"
            @click="redirectToImportList"
          >
            Next
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getPlatformEnvironments,
  getDefaultEnvironment
} from "@/helpers/utils";

export default {
  name: "ImportList",
  data() {
    return {
      fields: [
        { key: "flagImage", label: "Select a region" },
        { key: "link_to_sheet", tdClass: "open_sheet_link" }
      ],
      selectedRegion: null,
      platformEnvs: getPlatformEnvironments(),
      selectedPlatform: getDefaultEnvironment()
    };
  },
  computed: mapState(["importSheets"]),
  created() {
    this.$store.dispatch("FETCH_USER_INFO");
    this.$store.dispatch("FETCH_IMPORT_SHEETS");
  },
  methods: {
    onClick(regionId) {
      this.$router.push({
        path: `/import/${regionId}`
      });
    },
    selectRegion(region) {
      this.selectedRegion = region;
    },
    rowClass(item) {
      if (this.selectedRegion && this.selectedRegion.id === item.id) {
        return "table-success";
      }
    },
    redirectToImportList() {
      window.location.href = `/import/${this.selectedRegion.id}?selectedPlatform=${this.selectedPlatform}`;
    }
  }
};
</script>

<style>
.imports-sheets {
  margin: 2%;
}

.country-name {
  display: inline;
  margin-left: 3%;
  font-weight: 400;
}

.import-region {
  cursor: pointer;
}

.next-btn {
  margin-top: 2%;
  float: right;
}
.import-option-card {
  margin-top: 5%;
}
.country-radio-button label {
  width: 80%;
  cursor: pointer;
}
.region-list {
  max-height: 60vh;
  overflow-y: scroll;
}

.open_sheet_link {
  width: 100px;
}
</style>
