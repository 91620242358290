<template>
  <div
    :id="field.key + '_' + data.index"
    :key="field.key + '_' + data.index"
    class="cell-value"
  >
    {{ data.item[field.key] }}
    <pre>&nbsp;</pre>
    <b-popover
      boundary-padding="2"
      :target="field.key + '_' + data.index"
      :disabled="
        !data.item.errors[field.key] || data.item.errors[field.key].length == 0
      "
      triggers="hover"
      variant="dark"
      placement="top"
      title="dd;l"
    >
      <template v-slot:title>Please fix the below errors <b-icon icon="info-circle" 
      v-b-tooltip.hover title="For more information click on info icon in the cell header">
      </b-icon></template>
      <b-list-group
        v-for="errorMessage in data.item.errors[field.key]"
        v-bind:key="errorMessage"
      >
        <b-list-group-item>
          {{ errorMessage }}
        </b-list-group-item>
      </b-list-group>
       </b-popover >
        
  </div>
</template >

<script>
export default {
  name: "CellItem",
  props: {
    field: {
      type: Object
    },
    data: {
      type: Object
    }
  }
};
</script>
