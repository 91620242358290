<template>
  <div
    :class="`head_${field.key}`"
    :style="`width: ${getWidth()}px;`"
  >
    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none header-menu-button"
      no-caret
    >
      <template v-slot:button-content>
        <b-icon icon="three-dots-vertical"></b-icon>
      </template>
      <b-dropdown-item v-b-modal="`${field.key}-info-modal`">
        <b-icon icon="info-circle"></b-icon> Info
      </b-dropdown-item>
      <b-dropdown-item
        v-if="copyEnabledFields.includes(field.key)"
        @click="copyValues(field.key)"
      >
        <b-icon icon="files"></b-icon> Copy
      </b-dropdown-item>
    </b-dropdown>
    {{ field.label }}
    <b-modal
      :id="`${field.key}-info-modal`"
      :title="field.label"
      ok-only
    >
      <p><b>Validations</b></p>
      <ul>
        <li
          v-for="item in field.validations"
          :key="item.type"
        >
          {{ item.message }}
          <div v-if="item.type == 'enum_contains'">
            <ul>
              <li
                v-for="value in item.possible_values.filter(value => value)"
                :key="value"
              >
                {{value}}
              </li>
            </ul>
          </div>
          <div v-if="item.type == 'dependent_enum_contains'">
            <ul>
              <li
                v-for="value in item.possible_values.filter(value => value)"
                :key="value"
              >
                {{value}}
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <p v-if="!field.validations || field.validations.length === 0">
        No Validations
      </p>
    </b-modal>
  </div>
</template>

<script>
import pixelWidth from "string-pixel-width";

export default {
  name: "CellHeaderItem",
  props: {
    field: {
      type: Object,
    },
    copyValues: Function
  },
  data: () => {
    return {
      copyEnabledFields: ['serial_number']
    }
  },
  methods: {
    getWidth: function () {
      return pixelWidth(this.$props.field.label, { size: 16 }) + 50;
    }
  }
};
</script>
