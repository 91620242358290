<template>
  <div class="logged-out">
    <div>
      <b-card
        border-variant="dark"
        header="You have been logged out !!"
        align="center"
      >
        <b-card-text>
          <a :href="url" class="login">
            <b-button variant="primary">Login</b-button></a
          >
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logout",
  data() {
    return {
      url: `/`
    };
  }
};
</script>

<style>
.logged-out {
  width: 600px;
  margin: auto;
  margin-top: 10%;
}

.logged-out .login {
  margin: 40px;
}
</style>
