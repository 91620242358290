<template>
  <div>
    <div v-if="isAppBusy">
      <div class="d-flex justify-content-center mb-3 custom-loader">
        <b-spinner large type="grow" />
      </div>
      <p class="loading-message">{{ loadingMessage }}</p>
    </div>
    <div v-if="!isAppBusy">
      <ImportHeader
        :import-label="imports.importData.label"
        :importDescription="imports.importData.description"
        :country="imports.importData.country"
        :asset-count="tableRows.length"
        :error-count="validationErrorCount"
        :is-import-enabled="isReadyForImport"
        :sheet-id="imports.importData.sheetId"
      />
      <div class="row">
        <div class="sheet-data-wrapper">
          <b-table
            striped
            hover
            bordered
            top-row
            head-row-variant="secondary"
            sticky-header="75vh"
            id="asset-table"
            :fields="getTableHeaderFields"
            :busy="isBusy"
            :items="filteredRows"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template
              v-for="field in getTableHeaderFields"
              v-slot:[`head(${field.key})`]=""
            >
              <CellHeaderItem v-bind:key="field.key" v-bind:field="field" :copyValues="copyValues" />
            </template>

            <template
              v-for="field in getTableHeaderFields"
              :id="test"
              v-slot:[`cell(${field.key})`]="data"
            >
              <CellItem v-bind:key="field.key" v-bind:field="field" v-bind:data="data" />
            </template>

            <template v-slot:cell(google_r_no)="data">
              <span class="asset-index">
                {{
                data.item.rowNumber
                }}
              </span>
            </template>

            <template v-slot:cell(platform_exist_status)="data">
              <PlatformExistStatus v-bind:value="data.item.platformExistStatus" />
            </template>

            <template v-slot:cell(import_status)="data">
              <div
                :set="importStatus = imports.importSummary.details[data.item.serial_number] == undefined ? 'pending' :  imports.importSummary.details[data.item.serial_number]"
              >
                <span v-bind:class="['asset-import-status',importStatus]">
                  <b-spinner
                    v-if="importStatus == 'pending'"
                    style="width: 1rem; height: 1rem;"
                    type="grow"
                  />
                  {{importStatus}}
                </span>
              </div>
            </template>

            <div slot="table-busy" class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </b-table>
          <b-pagination
              v-model="currentPage"
              pills
              :total-rows="filteredRows.length"
              size = "sm"
              :per-page="perPage"
              align="center"
              aria-controls="asset-table"
            ></b-pagination>
          <div class ="per-page-asset-count">*Maximum 250 records per page</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ImportHeader from "@/components/ImportHeader.vue";
import PlatformExistStatus from "@/components/import/PlatformExistStatus";
import CellItem from "@/components/import/CellItem";
import CellHeaderItem from "@/components/import/CellHeaderItem";

export default {
  name: "SheetData",
  components: {
    ImportHeader,
    PlatformExistStatus,
    CellItem,
    CellHeaderItem
  },
  data(){
    return{
      currentPage: 1,
      perPage: 250,
    }
  },
  computed: {
    ...mapState({
      imports: state => state.imports,
      platformEnv: state => state.platformEnv,
      importSheets: state => state.importSheets,
      isAppBusy: state => state.isAppBusy,
      loadingMessage: state => state.loadingMessage
    }),
    tableRows: function () {
      const getPlatformExistStatus = assetIndex => {
        if (
          Object.prototype.hasOwnProperty.call(
            this.imports.platformAssetData,
            assetIndex
          )
        ) {
          return this.imports.platformAssetData[assetIndex] ? "exists" : "new";
        }
        return "checking";
      };

      return (this.imports.importData.data &&
          this.imports.importData.data.map((asset, index) => {
            const _rowVariant = this.imports.selectedAssetIndices.includes(
              index
            )
              ? "success"
              : "";
            const platformExistStatus = getPlatformExistStatus(index);

            let result = Object.assign({}, asset, {
              platformExistStatus,
              _rowVariant,
              rowNumber: index + this.imports.importData.headerRowIndex + 2,
              _cellVariants: Object.keys(asset.errors).reduce(
                (variants, key) => {
                  variants[key] = "danger";
                  return variants;
                },
                {}
              )
            });

            const validateLocationStatus = (fieldName, result, locationValidityStatus, importData) => {
              if(importData.checkLocationFieldForSelectedRegion.includes(fieldName) && locationValidityStatus[fieldName]){
                if(!locationValidityStatus[fieldName][result[fieldName]]){
                    const errorMsg = "The location "+result[fieldName]+" doesn't exist for "+importData.country+" in ServiceNow. Could you please use the correct sheet to import?";    
                    if (
                      Object.prototype.hasOwnProperty.call(
                        result.errors,
                        fieldName
                      ) 
                    ) {
                      if (!result.errors[fieldName].includes(errorMsg))
                        result.errors[fieldName].push(errorMsg);
                    } else {
                      result.errors[fieldName] = [errorMsg];
                    }
                    result._cellVariants[fieldName] = "danger";
                  }
              }
            }

            Object.keys(this.imports.metadataExistStatus).map(metadataKey => {
              let isCaseSensitiveError = false;
              let isMetadataExists;
              const checkMetadataItem = this.imports.importData.checkMetadataItems.find(
                checkMetadataItem =>
                  checkMetadataItem.metadataKey === metadataKey
              );
              if (checkMetadataItem) {
                isMetadataExists = this.imports.metadataExistStatus[
                  metadataKey
                ].find(item => {
                  const metaDataValue = checkMetadataItem.keys
                    .map(keyItem => asset[keyItem])
                    .join(" ");

                  const isMatch = metaDataValue === item.value && item.isExist;
                  if (!isMatch && !isCaseSensitiveError) {
                    isCaseSensitiveError =
                      metaDataValue.toLowerCase().replace(/ /g, "") === item.value.toLowerCase().replace(/ /g, "");
                  }
                  return isMatch;
                });
              }

              if (result[metadataKey] && !isMetadataExists && !result.errors[metadataKey]) {
                const errorMsg =
                  "Value provided does not exist in ServiceNow. Raise a ticket to Servicenow-support to have the value created before you try to import.";
                if (
                  Object.prototype.hasOwnProperty.call(
                    result.errors,
                    metadataKey
                  ) 
                ) {
                  if (!result.errors[metadataKey].includes(errorMsg))
                    result.errors[metadataKey].push(errorMsg);
                } else {
                  result.errors[metadataKey] = [errorMsg];
                }
                const caseSensitiveError =
                  "Please check upper/lower cases or spaces. Should match exactly as in ServiceNow";
                if (
                  isCaseSensitiveError &&
                  !result.errors[metadataKey].includes(caseSensitiveError)
                ) {
                  result.errors[metadataKey].push(caseSensitiveError);
                }
                result._cellVariants[metadataKey] = "danger";
              }
              else if(isMetadataExists){
                validateLocationStatus(metadataKey, result, this.imports.locationValidityStatus, this.imports.importData)
              }
            });

            Object.keys(this.imports.locationValidityStatus).map(locationField => {
              if(this.imports.importData.checkMetadataItems.find( metaDataItem => metaDataItem.metadataKey == locationField ) == undefined){
                if (result[locationField] && !result.errors[locationField]) {
                  validateLocationStatus(locationField, result, this.imports.locationValidityStatus, this.imports.importData)
                }
              }
            });

            if(this.imports.importData.checkPurchaseLocationFromPlatform){
              if(!result['purchase_location']){
                const locationStatus = this.imports.locationValidityBySerialNumber[result['serial_number']];
                if(locationStatus && !locationStatus.isValid){
                  const errorMsg = "This asset doesn't belong to "+this.imports.importData.country+". Based on the data from ServiceNow you should update this from "+locationStatus.country+" sheet.";
                  if (
                    Object.prototype.hasOwnProperty.call(
                      result.errors,
                      'serial_number'
                    ) 
                  ) {
                    if (!result.errors['serial_number'].includes(errorMsg))
                      result.errors['serial_number'].push(errorMsg);
                  } else {
                    result.errors['serial_number'] = [errorMsg];
                  }
                  result._cellVariants['serial_number'] = "danger";
                }
              }
            }

            if (platformExistStatus === "exists") {
              const platformAssetData = this.imports.platformAssetData[index];

              Object.keys(platformAssetData).forEach(key => {
                if (
                  platformAssetData[key] &&
                  platformAssetData[key] !== asset[key]
                ) {
                  const errorMsg = `Value in ServiceNow is "${platformAssetData[key]}". Please enter the same `;

                  if (
                    Object.prototype.hasOwnProperty.call(result.errors, key)
                  ) {
                    if (!result.errors[key].includes(errorMsg))
                      result.errors[key].push(errorMsg);
                  } else {
                    result.errors[key] = [errorMsg];
                  }
                  result._cellVariants[key] = "danger";
                }
              });
            }

            Object.keys(this.imports.dependentMandatoryStatus).map(metadataKey=>{
              const dependentKey = (this.imports.importData.dependentMandatoryFields[0].dependentKey)
              let isMandatory = {}
              let dependentLabel
              let label
              const dependentMandatoryItem = this.imports.importData.dependentMandatoryFields.find(
                dependentMandatoryField =>
                  dependentMandatoryField.metadataKey === metadataKey)
                 if (dependentMandatoryItem) {
                    let dependentMandatoryStatus = this.imports.dependentMandatoryStatus
                    dependentMandatoryStatus[metadataKey].map(({ value, isExist }) => {
                      isMandatory[value] = !isExist
                    }
                );
              }

               this.getTableHeaderFields.map(item=>{
                 if (item.key == dependentKey)
                    dependentLabel = item.label
                  if(item.key == metadataKey)
                    label = item.label
               })
               
              if(result[dependentKey]!= "" && 
                (((Array.isArray(result.errors[dependentKey]) && result.errors[dependentKey].length==0) && 
                result[metadataKey] == "" && isMandatory[result[dependentKey]])|| 
                (!Array.isArray(result.errors[dependentKey]) && 
                result[metadataKey] == "" && isMandatory[result[dependentKey]])
                )
              ){
                const errorMsg =`This is a mandatory field as the ${dependentLabel} has no ${label}`;
                if ( Object.prototype.hasOwnProperty.call(result.errors,metadataKey) && errorMsg != ""
                ) {
                  if (!result.errors[metadataKey].includes(errorMsg))
                    result.errors[metadataKey].push(errorMsg);
                  } else {
                  result.errors[metadataKey] = [errorMsg];
                  }
              }
              if (isMandatory[result[dependentKey]] && !isMandatory[result[dependentKey]] && result[metadataKey] != ""){
                const errorMsg =`Please remove this value as the ${dependentLabel} has ${label}`;
                if ( Object.prototype.hasOwnProperty.call(result.errors,metadataKey) && errorMsg != ""
                ) {
                  if (!result.errors[metadataKey].includes(errorMsg))
                    result.errors[metadataKey].push(errorMsg);
                  } else {
                  result.errors[metadataKey] = [errorMsg];
                  }
              }
              })

             this.imports.importData.assetTypeBasedValidation.map((validation)  => {
                  if (asset[validation.metadataKey]) {
                    const serialNumber = asset['serial_number']
                    const capexDetails = this.imports.assetTypeMap[serialNumber]
                    if (!capexDetails){
                      return;
                    }
                    let validForAssetType= true;
                    if (capexDetails.isCapex && capexDetails.purchaseDate && new Date(capexDetails.purchaseDate) >= new Date(this.imports.fixedAssetPolicyDate)) {
                        validForAssetType = false
                    }
                    if (!validForAssetType) {
                      if (!result.errors[validation.metadataKey]){
                        result.errors[validation.metadataKey] = []
                      }
                      let errorMsg = `This field is not applicable for the assets of type "${capexDetails.type}" purchased on or after 2021.
                       Please remove value from this field and add in Base cost and tax fields`
                      if (!result.errors[validation.metadataKey].includes(errorMsg)){
                          result.errors[validation.metadataKey].push(errorMsg)
                      }
                      result._cellVariants[validation.metadataKey] = "danger";
                    }
                  }
             })
            
            return result;
          })) || []
    },
    filteredRows:  function (){
        return this.filterTableRows(this.tableRows)
    },
    isAnyOneAssetSelected: function () {
      return this.imports.selectedAssetIndices.length ? true : false;
    },
    isBusy: function () {
      return this.imports.importData.data ? false : true;
    },
    getTableHeaderFields: function () {
      if (this.imports.importData.fields) {
        let prefixColumns = [
          { key: "google_r_no", label: "Row No", stickyColumn: true, isRowHeader: true, variant: 'secondary' },
          { key: "platform_exist_status", label: "State" }
        ]
        if(this.imports.showStatus){
          prefixColumns.push({ key: "import_status", label: "Import Status" })
        }
        return [
          ...prefixColumns,
          ...this.imports.importData.fields
        ];
      }
      return [];
    },
    validationErrorCount: function () {
      return this.tableRows.filter(
        item => Object.keys(item.errors).length > 0
      ).length;
    },
    isReadyForImport: function () {
      const errorMessages = [];
      const hasError = this.tableRows.find(
        item => Object.keys(item.errors).length > 0
      )
        ? true
        : false;

      if (hasError) {
        errorMessages.push("Please fix all errors to perform import operation");
      }

      let actionCheck = false;
      const allowedActions = this.imports.importData.allowedActions.join(",");
      switch (allowedActions) {
        case "insert": {
          actionCheck = Object.keys(this.imports.platformAssetData).find(
            index => this.imports.platformAssetData[index]
          )
            ? true
            : false;

          if (actionCheck) {
            errorMessages.push("Only new assets are allowed to import");
          }
          break;
        }
        case "update": {
          actionCheck = Object.keys(this.imports.platformAssetData).find(
            index => !this.imports.platformAssetData[index]
          )
            ? true
            : false;

          if (actionCheck) {
            errorMessages.push("Only existing assets are allowed to import");
          }
          break;
        }
      }

      return {
        status: !hasError && !actionCheck,
        errorMessages: errorMessages
      };
    }
  },
  created() {
    this.$store.dispatch("FETCH_USER_INFO");
    return this.$store.dispatch("getImportDataByRegionId", {
      regionId: this.$route.params.regionId,
      platformEnv: this.$route.query.selectedPlatform
    });
  },
  methods: {
    toggleAssetSelection(assetData) {
      if (Object.keys(assetData.item.errors).length === 0) {
        this.$store.dispatch("toggleAssetSelection", {
          assetIndex: assetData.index
        });
      }
    },
    toggleAllAssetSelection() {
      this.$store.dispatch("toggleAllAssetSelection");
    },
    isAssetSelected(assetIndex) {
      return this.imports.selectedAssetIndices.includes(assetIndex);
    },
    filterTableRows: function (tableRows) {
      if (this.imports.filter.selectedFilter) {
        if(this.imports.filter.selectedFilter == 'errors'){
          return tableRows.filter(
            item => Object.keys(item.errors).length > 0
          );
        }
        return tableRows.filter(
          item =>
            item.platformExistStatus === this.imports.filter.selectedFilter
        );
      }
      return tableRows;
    },
    copyValues: function (columnKey) {
      const values = this.filterTableRows(this.tableRows).map(
        item => item[columnKey]
      );
      const str = values.join("\n");
      var el = document.createElement("textarea");

      el.value = str;
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    }
  }
};
</script>

<style>
.sheet-data-wrapper {
  overflow-y: scroll;
  height: 80vh;
}
.sheet-data-wrapper>div{
  margin: 0px;
}

body {
  padding: 1rem;
}

.title {
  font-size: 20px;
}
.platform-env-select {
  text-transform: capitalize;
  width: 100%;
}

.table-danger > .cell-value {
  background: #f5c6cb;
  cursor: pointer;
}

.cell-value {
  position: relative;
}

.custom-loader {
  margin-top: 5%;
}

.loading-message {
  text-align: center;
  color: #333;
  font-size: 13px;
  font-style: italic;
}

.asset-index {
  margin-right: 30px;
}
.head_serial_number {
  cursor: pointer;
}

.copy-item {
  font-size: 10px;
}

.header-menu-button {
  padding: 2px !important;
}

.asset-import-status {
  text-transform: capitalize;
  font-weight: bold;
}

.asset-import-status.success {
  color: green;
}
.asset-import-status.failure {
  color: red;
}
.asset-import-status.pending {
  color: orange;
}
.pagination{
  margin: 5px;
}

.per-page-asset-count{ 
float:right;
margin: -25px 10px 10px 10px!important;
font-size: 12px;
font-style: italic;
}
</style>
