<template>
  <div class="header">
    <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top">
        <b-row class="bv-row">
          <b-col cols="11"><b-navbar-brand href="/">
        Asset Importer
      </b-navbar-brand></b-col>
          <b-col cols="1">      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown v-if="userInfo" right>
          <template v-slot:button-content>
            <span>{{ userInfo.firstName }} {{ userInfo.lastName }}</span>
          </template>
          <b-dropdown-item :href="logoutURL">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav></b-col>
        </b-row>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Navbar",
  computed: mapState(["userInfo"]),
  data: function () {
    return {
      logoutURL: `${process.env.VUE_APP_API_HOST}/user/auth/logout`
    };
  }
};
</script>

<style>
.user-picture {
  width: 30px;
  margin-right: 10px;
}
.header {
  margin-bottom: 50px;
}
.bv-row {
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
}
</style>
