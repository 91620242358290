<template>
  <div>
    <div v-if="value === 'checking'">
      <b-spinner type="grow" />
    </div>
    <div v-if="value === 'exists'">
      <b>
        <span class="dot bg-secondary"></span>
          Exist
      </b>
    </div>
    <div v-if="value === 'new'">
      <b>
        <span class="dot bg-info"></span>
          New
      </b>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformExistStatus",
  props: {
    value: { type: String, default: "" }
  }
};
</script>

<style>
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
</style>