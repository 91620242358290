import {
  fetchUserInfo,
  fetchImportSheets
} from "../api";

import {getDefaultEnvironment} from "../helpers/utils"
export default {
  FETCH_USER_INFO: ({ commit }) => {
    return fetchUserInfo().then(response => {
      commit("SET_USER_INFO", { userInfo: response });
    });
  },
  FETCH_IMPORT_SHEETS: ({ commit }) => {
    const env = getDefaultEnvironment()
    return fetchImportSheets(env).then(response => {
      commit("SET_IMPORT_SHEETS", { importSheets: response });
    });
  },
  selectPlatformEnv: ({ commit }, { platformEnv }) => {
    commit("SET_PLATFORM_ENV", { platformEnv });
  },
  SET_IMPORT_STATUS: ({ commit }, { importStatus }) => {
    commit("SET_IMPORT_STATUS", { importStatus });
  },
  setLoadingMessage: ({ commit }, { isAppBusy, message }) => {
    commit("SET_APP_BUSY_STATE", { isAppBusy, message });
  }
};
