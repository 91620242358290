export const ADD_ASSETS_FIELDS = "model.name,install_status,serial_number";
export const MANUFACTURER_FIELDS = "name,sys_id";
export const VENDOR_FIELDS = "name,sys_id";
export const USER_FIELDS = "sys_id,user_name";
export const MODEL_FIELDS = "sys_id,name";

export const PLATFORM_ENVIRONMENTS = {
  development: [
    { value: null, text: "Select servicenow environment" },
    { value: "development", text: "Development" },
    { value: "test", text: "Test" },
    { value: "uat", text: "UAT" }
  ],
  prod: [{ value: "prod", text: "Production" }]
};
